import React from 'react'
import { NavLink } from 'react-router-dom';
const Product = ({ProductData ,name}) => {
  return (
  
        <div className="row">
        {ProductData.data.map((item, index) => (
          <div className="col-md-4 col-sm-6 mt-4" key={index}>
            <div className="wrapper">
            <div className="img-wrapper">
            <img src={item.img} alt="category" />
            </div>
            <div className="text-wrapper">
            <div className="text text-center p-3">
            <h4 className="">
            {name}</h4>
           <NavLink className="effect" to="/contact-us"> Contact Us Now</NavLink>
            </div>
            </div>
            </div>
          </div>
          ))}
        </div>
   
  )
}

export default Product