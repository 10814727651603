import React, { useState, useEffect } from "react";
import Slider from "react-slick";
const HomeCategories = () => {
  const [popupImage, setPopupImage] = useState(null);

  const settings = {
    infinite: true,
    dots: true,
    arrows: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    speed: 1000,
    // autoplay: true,
    // autoplaySpeed: 3000,
    // pauseOnHover: true,
    responsive: [
     
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
         
        }
      },
      
    ]
  };

  const products = {
    data: [
      { image: "/images/gallery/glass1.jpeg" },
      { image: "/images/gallery/glass2.jpeg" },
      { image: "/images/gallery/glass3.jpeg" },
      { image: "/images/gallery/glass4.jpeg" },
      { image: "/images/gallery/glass5.jpeg" },
      { image: "/images/gallery/glass6.jpeg" },
      { image: "/images/gallery/glass7.jpeg" },
      { image: "/images/gallery/glass8.jpeg" },
      { image: "/images/gallery/glass9.jpeg" },
      { image: "/images/gallery/glass10.jpeg" },
      { image: "/images/gallery/glass11.jpeg" },
      { image: "/images/gallery/glass12.jpeg" },
      { image: "/images/gallery/glass13.jpeg" },
      { image: "/images/gallery/glass14.jpeg" },
      { image: "/images/gallery/glass15.jpeg" },
      { image: "/images/gallery/glass16.jpeg" },
      { image: "/images/gallery/glass17.jpeg" },
      { image: "/images/gallery/glass18.jpeg" },
      { image: "/images/gallery/glass19.jpeg" },
      { image: "/images/gallery/glass20.jpeg" },
      { image: "/images/gallery/glass21.jpg" },
      { image: "/images/gallery/glass22.jpg" },
    ],
  };
  const car = {
    data: [
      { image: "/images/gallery/car/car1.jpeg" },
      { image: "/images/gallery/car/car2.jpeg" },
      { image: "/images/gallery/car/car3.jpeg" },
      { image: "/images/gallery/car/car4.jpeg" },
      { image: "/images/gallery/car/car5.jpeg" },
    ],
  };
  const jwellery = {
    data: [
      { image: "/images/gallery/Jewelry/Jewelry1.jpeg" },
      { image: "/images/gallery/Jewelry/Jewelry2.jpeg" },
      { image: "/images/gallery/Jewelry/Jewelry3.jpeg" },
      { image: "/images/gallery/Jewelry/Jewelry4.jpeg" },
    ],
  };
  const clothes = {
    data: [
      { image: "/images/gallery/clothes/clothes1.jpeg" },
      { image: "/images/gallery/clothes/clothes2.jpeg" },
      { image: "/images/gallery/clothes/clothes3.jpeg" },
     
    ],
  };
  

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.keyCode === 27) {
        closePopup();
      }
    };

    if (popupImage) {
      
      window.addEventListener("keydown", handleEscape);
    } else {
      document.body.style.overflow = "unset";
    }

    return () => window.removeEventListener("keydown", handleEscape);
  }, [popupImage]);

  const handleImageClick = (image) => {
    setPopupImage(`${image}`);
  };

  const closePopup = () => {
    setPopupImage(null);
  };
  return (
    <section className="py-5 bg2">
      <div className="container categories-wrapper">
        <div className="heading text-center pb-4">
          <span>Our Latest</span>
          <h2>Projects</h2>
          <p>
            Step into the world of contemporary elegance and innovative design
            with our latest projects. Each space is a testament to our
            commitment to excellence, showcasing cutting-edge concepts in
            optical showroom design.
          </p>
        </div>
        <h3>Optical</h3>

        <div className="gallery-slider-wrapper">
        <Slider {...settings}>
          {products.data.map((item, index) => (
            <div
              key={index}
              className={`col-md-3 col-sm-6 py-3 prowrap  `}
            >
              <div
                className="img g-img"
                onClick={() => handleImageClick(item.image)}
              >
              <div className="img-wrapper">
              <img src={item.image} alt={`Image ${index}`} />
              </div>
                
              </div>
            </div>
          ))}

          </Slider>
        </div>
<h3>car accessories</h3>
        <div className="gallery-slider-wrapper">
        <Slider {...settings}>
          {car.data.map((item, index) => (
            <div
              key={index}
              className={`col-md-3 col-sm-6 py-3 prowrap ${item.category} `}
            >
              <div
                className="img g-img"
                onClick={() => handleImageClick(item.image)}
              >
              <div className="img-wrapper">
              <img src={item.image} alt={`Image ${index}`} />
              </div>
              </div>
            </div>
          ))}
          </Slider>
        </div>
<h3>Jewelry</h3>
        <div className="gallery-slider-wrapper">
        <Slider {...settings}>
          {jwellery.data.map((item, index) => (
            <div
              key={index}
              className={`col-md-3 col-sm-6 py-3 prowrap ${item.category} `}
            >
              <div
                className="img g-img"
                onClick={() => handleImageClick(item.image)}
              >
              <div className="img-wrapper">
              <img src={item.image} alt={`Image ${index}`} />
              </div>
              </div>
            </div>
          ))}
          </Slider>
        </div>

        <h3>Clothes Showroom</h3>
        <div className="gallery-slider-wrapper">
        <Slider {...settings}>
          {clothes.data.map((item, index) => (
            <div
              key={index}
              className={`col-md-3 col-sm-6 py-3 prowrap ${item.category} `}
            >
              <div
                className="img g-img"
                onClick={() => handleImageClick(item.image)}
              >
              <div className="img-wrapper">
              <img src={item.image} alt={`Image ${index}`} />
              </div>
              </div>
            </div>
          ))}
          </Slider>
        </div>
        {popupImage && (
          <div className="popup" onClick={closePopup}>
            <span className="close-btn">&times;</span>
            <img src={popupImage} alt="Popup Image" className="popup-img" />
          </div>
        )}
      </div>
    </section>
  );
};

export default HomeCategories;
