import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FaBarsStaggered } from "react-icons/fa6";

const Header = () => {
  const [nav, setNav] = useState();
  const[tab2 ,setTab2] = useState()
  const navToggle = () => {
    setNav(!nav);
  };

  const allClose = () => {
    
    setNav(false);
  };

  return (
    <div>
      <header>
        <div className="header position-relative">
        <div className="container">
        <nav>
            <div className="nav-left">
              <div className="logo  ">
                <NavLink onClick={allClose} to="/">
                  <img src="/images/logo.png" alt="logo" />
                </NavLink>
              </div>
            </div>
            <div className="nav-right">
              <div className={nav ? "menu active" : "menu "}>
                <ul className="main-ul mb-0">
                  <li>
                    <NavLink onClick={allClose} to="/">
                      Home
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={allClose} to="/about-us">
                      Who we are
                    </NavLink>
                    <div className={tab2 ? "drop  open" : "drop"} >
                <div className="drop-content-wrapper">
                  <ul className="main-page-link-wrapper">
                    <li><NavLink   to="/about-us">About Us </NavLink></li> 
                    <li><NavLink   to="/">Gallery </NavLink></li> 
                    <li><NavLink   to="/">Blog </NavLink></li> 
                  </ul>
                </div>
              </div>
                  </li>
                  <li>
                  <NavLink onClick={allClose} to="/showroom">
                  Showrooms
                  </NavLink>
                  <div className={tab2 ? "drop  open" : "drop"} >
              <div className="drop-content-wrapper">
                <ul className="main-page-link-wrapper">
                
                <li><NavLink to="/showroom#optical-showroom">Optical Showroom Design</NavLink></li>
                <li><NavLink to="/showroom#watch-showroom">Watch Showroom Design</NavLink></li>
                <li><NavLink to="/showroom#jewellery-showroom">Jewellery Showroom Design</NavLink></li>
              </ul>
              </div>
            </div>
                </li>
                <li>
                <NavLink onClick={allClose} to="/optical-display">
                Optical Display
                </NavLink>
                <div className={tab2 ? "drop  open" : "drop"} >
            <div className="drop-content-wrapper">
              <ul className="main-page-link-wrapper">
              <li><NavLink to="/optical-display#cash-counter">Cash Counter</NavLink></li>
              <li><NavLink to="/optical-display#front-counter">Front Counter</NavLink></li>
              <li><NavLink to="/optical-display#back-wall"optical-display>Optical Back Wall Display</NavLink></li>
              <li><NavLink to="/optical-display#table-top">Table Top</NavLink></li>
              <li><NavLink to="/optical-display#floor-stands">Floor Stands</NavLink></li>
            </ul>
            </div>
          </div>
              </li>
                  <li>
                    <NavLink onClick={allClose} to="/service">
                     Our Services
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={allClose} to="/contact-us">
                      Contact Us
                    </NavLink>
                  </li>
                </ul>
              </div>

              <div className="bar">
                <span onClick={() => navToggle()}>
                  <FaBarsStaggered />
                </span>
              </div>
            </div>
          </nav>
        </div>
          
        </div>
      </header>
    </div>
  );
};

export default Header;
