import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import CourseBanner from "../components/CourseBanner";

const Service = () => {
  const { pathname } = useLocation();
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const { hash } = location;
    if (hash) {
      const element = document.getElementById(hash.replace("#", ""));
      if (element) element.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  const bannerData = {
    image: "/images/service-banner.jpg",
    heading: "Our Services",
    heading2:"Crafting Excellence, Designing Dreams",
    para: "Embark on a journey of transformation with The Noor Art's bespoke services. From conceptualization to execution, our team is dedicated to excellence in showroom design. We offer a comprehensive suite of services tailored to your unique brand, ensuring a seamless and satisfying project experience.",
  };
  return (
    <div className="page-content">
      <Helmet>
        <title>Our Services at The Noor Art | Tailored Showroom Design Solutions</title>
        <meta
          name="description"
          content="
          Explore our range of services at The Noor Art. From custom showroom designs to project management, we offer comprehensive solutions to bring your brand's vision to life in a captivating retail space."
        />
        <link rel="canonical" href="https://steamvarsity.com/about-us" />
      </Helmet>
      <CourseBanner {...bannerData} />
      <section className="bg2" id="service1">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-6">
              <div className="wrapper">
                <h2>Custom Showroom Design</h2>
                <p>
                  At The Noor Art, our Custom Showroom Design service is the
                  cornerstone of our creative offerings. We begin by
                  understanding the unique story and vision of your brand. This
                  insight forms the foundation upon which we craft your
                  showroom's design. Each design is a one-of-a-kind creation,
                  tailored to reflect your brand's identity and ethos. Our team
                  employs innovative design techniques and creative concepts to
                  ensure that the final space is not only visually stunning but
                  also resonates deeply with your target audience. From color
                  schemes and material selections to the overall layout, every
                  element is chosen to create a cohesive and branded environment
                  that stands out in the competitive retail landscape.
                </p>
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <div className="img-wrapper">
                <img src="/images/service/about2.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="service2">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-5">
              <div className="img-wrapper">
                <img src="/images/service/about3.jpg" alt="" />
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-6">
              <div className="wrapper">
                <h2> Space Planning and Layout</h2>

                <p>
                  Effective Space Planning and Layout are crucial for a
                  successful showroom. At The Noor Art, we meticulously plan
                  every inch of your space to optimize customer flow and
                  engagement. Our strategic approach involves analyzing customer
                  movement patterns and product placement to create a layout
                  that is both intuitive and inviting. We consider factors like
                  visibility, accessibility, and comfort to ensure that your
                  customers have a seamless shopping experience. Our layouts are
                  designed not just to display products but to create a
                  narrative journey through the space, guiding customers
                  naturally from one section to another while ensuring maximum
                  exposure to key products.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg2" id="service3">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-6">
              <div className="wrapper">
                <h2>Lighting and Visual Elements</h2>

                <p>
                  Lighting and Visual Elements play a pivotal role in setting
                  the mood and highlighting your products. Our team at The Noor
                  Art utilizes the latest in lighting technology to create the
                  perfect ambiance for your showroom. We understand how
                  different lighting styles can impact customer perception and
                  interaction with your products. From accent lighting that
                  draws attention to specific items to ambient lighting that
                  creates a warm and welcoming environment, our lighting
                  solutions are both functional and aesthetically pleasing.
                  Additionally, we incorporate visual elements such as graphics,
                  digital displays, and signage to enhance the customer
                  experience and reinforce your brand identity.
                </p>
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <div className="img-wrapper">
                <img src="/images/service/about4.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="service4">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-5">
              <div className="img-wrapper">
                <img src="/images/service/about5.jpg" alt="" />
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-6">
              <div className="wrapper">
                <h2>Branding and Aesthetic Enhancement</h2>

                <p>
                  Our service in Branding and Aesthetic Enhancement focuses on
                  seamlessly integrating your brand into every aspect of the
                  showroom design. We believe that a showroom should be an
                  extension of your brand's story. Our team works closely with
                  you to understand your brand values, messaging, and visual
                  identity. This understanding allows us to infuse your brand's
                  essence into the design elements, from color palettes and
                  textures to signage and artwork. Our goal is to create a space
                  that not only showcases your products but also communicates
                  your brand's unique identity and values to your customers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg2" id="service5">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-6">
              <div className="wrapper">
                <h2> Project Management and Execution</h2>

                <p>
                  Project Management and Execution at The Noor Art cover every
                  aspect of bringing your showroom from concept to reality. Our
                  comprehensive approach ensures that every stage of the project
                  is executed with precision and attention to detail. We manage
                  timelines, coordinate with contractors and suppliers, and
                  oversee the construction process to ensure that everything
                  aligns with the agreed-upon design and quality standards. Our
                  team keeps you informed and involved at every step, ensuring a
                  collaborative process. We are committed to delivering your
                  project on time and within budget, with a focus on exceeding
                  your expectations in terms of both quality and service.
                </p>
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <div className="img-wrapper">
                <img src="/images/service/about5.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Service;
