import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Product from "../components/Product";
import CourseBanner from "../components/CourseBanner";
const Showroom = () => {
  const { pathname } = useLocation();
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    const { hash } = location;
    if (hash) {
      const element = document.getElementById(hash.replace("#", ""));
      if (element) element.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  const OpticalData = {
    data: [
      {img: "/images/optical/optical1.jpeg",},
      {img: "/images/optical/optical3.jpeg",},
      {img: "/images/optical/optical5.jpeg",},
    ],
  };
  const JewelleryData = {
    data: [
      {img: "/images/jewellery/jewellery1.jpeg",},
      {img: "/images/jewellery/jewellery3.jpeg",},
      {img: "/images/jewellery/jewellery5.jpeg",},
    ],
  };
  const WatchData = {
    data: [
      {img: "/images/watch/watch1.jpeg",},
      {img: "/images/watch/watch3.jpeg",},
      {img: "/images/watch/watch5.jpeg",},
    ],
  };
  const bannerData = {
    image: "/images/showroom-banner.jpg",
    heading: "Showroom",
    heading2:"Where Every Space Tells a Story",
    para: "Step into the realm of bespoke showroom designs with The Noor Art. Our team is dedicated to creating spaces that resonate with your brand's story and captivate your clientele. Experience the fusion of aesthetics and functionality in our meticulously crafted showrooms that speak volumes of your brand's essence.",
  };
  return (
    <div className="page-content">
      <Helmet>
        <title>Showroom Design by The Noor Art | Optical, Watch, and Jewellery Spaces</title>
        <meta
          name="description"
          content="
          Enter the world of elegant showroom designs by The Noor Art. We specialize in creating bespoke spaces for optical, watch, and jewellery showrooms, ensuring each design is as unique as the products it showcases."
        />
       
      </Helmet>
      <CourseBanner {...bannerData} />
 <section className="bg2 product-container-wrapper " id="optical-showroom">
    <div className="container py-5">
      <div className="heading text-center pb-4">
        <span> Showroom Design</span>
        <h2>Optical</h2>
        <p>
        At The Noor Art, our Optical Showroom Designs are more than just display spaces; they are visionary realms where style meets functionality. We understand that an optical showroom needs to be both inviting and informative, allowing customers to explore and engage with your products in a relaxed environment.
        </p>
      </div>
      <Product ProductData={OpticalData} name="Optical Showroom Design" />
    </div>
  </section>
        <section className="bg2 product-container-wrapper " id="watch-showroom">
        <div className="container py-5">
          <div className="heading text-center pb-4">
            <span> Showroom Design</span>
            <h2>Watch</h2>
            <p>
            Crafting spaces for timepiece treasures, our Watch Showroom Designs speak the language of luxury and precision. Recognizing the exclusivity and elegance inherent in watch retailing, we create showroom environments that encapsulate these qualities. 
            </p>
          </div>
          <Product ProductData={WatchData} name="Watch Showroom Design" />
        </div>
      </section>
      <section className=" product-container-wrapper " id="jewellery-showroom">
      <div className="container py-5">
        <div className="heading text-center pb-4">
          <span> Showroom Design</span>
          <h2>Jewellery</h2>
          <p>
          Crafting spaces for timepiece treasures, our Watch Showroom Designs speak the language of luxury and precision. Recognizing the exclusivity and elegance inherent in watch retailing, we create showroom environments that encapsulate these qualities. 
          </p>
        </div>
        <Product ProductData={JewelleryData} name="Jewellery Showroom Design" />
      </div>
    </section>
 
    </div>
  );
};

export default Showroom;
