import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./slick.css";
import "./slick-theme.css";
import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Service from "./pages/Service";
import Showroom from "./pages/Showroom";
import OpticalDisplay from "./pages/OpticalDisplay";


function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/service" element={<Service />} />
        <Route path="/showroom" element={<Showroom />} />
        <Route path="/optical-display" element={<OpticalDisplay />} />

      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
