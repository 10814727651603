import React from "react";
import { Divider } from "antd";
import courseData from "./HomeProductData";
import {  Form, Input, Select } from "antd";

const Forme = ({ display }) => {

    const [form] = Form.useForm(); 
  
    const onFinish = async (values) => {
      try {
        const response = await fetch('https://formspree.io/f/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          body: JSON.stringify(values),
        });
  
        if (response.ok) {
          // Handle success
          alert('Form successfully submitted')
          console.log('Form successfully submitted');
          form.resetFields(); 
        } else {
          // Handle errors
          console.error('Form submission failed');
        }
      } catch (error) {
        // Catch and handle network errors
        console.error('Network error:', error);
      }
   


    
  };
  return (
    <div className="form-wrapper page-form-wrapper ">
      

      <Form form={form} onFinish={onFinish} id="survey_submit_form" layout="vertical">
      <Divider className={display}>Join STEAM Varsity </Divider>
        <Form.Item name="firstName">
          <Input placeholder="First Name" />
        </Form.Item>
        <Form.Item name="lastName">
          <Input placeholder="Last Name" />
        </Form.Item>
        <Form.Item name="email">
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item name="phone-number">
          <Input placeholder="Phone Number" type="number" />
        </Form.Item>
       
        <Form.Item name="showroom-type">
        <Input placeholder="Showroom Type" type="text" />
      </Form.Item>

        <button className="btn form-btn" htmlType="submit">
          Submit
        </button>
      </Form>
    </div>
  );
};

export default Forme;
