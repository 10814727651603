import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Product from "../components/Product";
import CourseBanner from "../components/CourseBanner";
const OpticalDisplay = () => {
  const { pathname } = useLocation();
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    const { hash } = location;
    if (hash) {
      const element = document.getElementById(hash.replace("#", ""));
      if (element) element.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);


  const CashData = {
    data: [
      {img: "/images/service/cash-counter.jpg",},
      {img: "/images/service/cash-counter2.jpg",},
      {img: "/images/service/cash-counter3.jpg",},
    ],
  };
  const FrontData = {
    data: [
      {img: "/images/service/counter1.jpg",},
      {img: "/images/service/counter2.jpg",},
      {img: "/images/service/counter3.jpg",},
    ],
  };
  const WallData = {
    data: [
      {img: "/images/service/wall1.jpg",},
      {img: "/images/service/wall2.jpg",},
      {img: "/images/service/wall3.jpg",},
    ],
  };
  const TableData = {
    data: [
      {img: "/images/service/table1.jpg",},
      {img: "/images/service/table2.jpg",},
      {img: "/images/service/table3.jpg",},
    ],
  };
  const FloorData = {
    data: [
      {img: "/images/service/stand.jpg",},
      {img: "/images/service/stand2.jpg",},
      {img: "/images/service/stand3.jpg",},
    ],
  };
  const bannerData = {
    image: "/images/optical-banner.jpg",
    heading: "Optical Display",
    heading2:"Visualize Perfection in Every Detail",
    para: "At The Noor Art, we understand that every detail matters in optical display. Our expert team specializes in crafting displays that not only showcase your eyewear collection but also enhance the customer experience. Let us help you create an optical display that is as functional as it is visually stunning",
  };
  return (
    <div className="page-content">
      <Helmet>
        <title>Optical Display Solutions by The Noor Art | Exceptional Design and Craftsmanship</title>
        <meta
          name="description"
          content="
          Specializing in optical showroom displays, The Noor Art offers innovative design solutions. Discover our expertise in crafting eye-catching cash counters, back wall displays, and more for your optical retail space"
        />
       
      </Helmet>
      <CourseBanner {...bannerData} />
 <section className="bg2 product-container-wrapper " id="cash-counter">
    <div className="container py-5">
      <div className="heading text-center pb-4">
        <span> Optical Display</span>
        <h2>Cash Counter</h2>
        <p>
        Our Cash Counter designs combine functionality with flair, creating a focal point that complements the aesthetic of your optical showroom. Meticulously crafted to manage transactions efficiently, these counters are not just practical but also enhance the overall shopping experience with their sleek and modern design.
        </p>
      </div>
      <Product ProductData={CashData} name="Optical Cash Counter" />
    </div>
  </section>
      
      <section className=" product-container-wrapper " id="front-counter">
      <div className="container py-5">
        <div className="heading text-center pb-4">
          <span> Optical Display</span>
          <h2>Front Counter</h2>
          <p>
          The Front Counter is where first impressions are made. Our designs are centered on creating an inviting and engaging space for customers as they enter. Balancing style and practicality, these counters are perfect for displaying new arrivals or featured products, making them instantly accessible to the clientele.
          </p>
        </div>
        <Product ProductData={FrontData} name="Optical Front Counter" />
      </div>
    </section>
   <section className="bg2 product-container-wrapper " id="back-wall">
        <div className="container py-5">
          <div className="heading text-center pb-4">
            <span> Optical Display</span>
            <h2>Back Wall Display</h2>
            <p>
            Our Optical Back Wall Display designs are masterpieces of organization and style. They serve as the primary showcase for your eyewear collection, displaying products in a way that's both aesthetically pleasing and easily navigable for customers. 
            </p>
          </div>
          <Product ProductData={WallData} name="Optical Back Wall Display" />
        </div>
      </section>
      <section className=" product-container-wrapper " id="table-top">
        <div className="container py-5">
          <div className="heading text-center pb-4">
            <span> Optical Display</span>
            <h2>Table Top</h2>
            <p>
            Table Top displays by The Noor Art are designed to offer an intimate and interactive product exploration experience. These displays are perfect for highlighting select pieces, allowing customers to easily view and try on different styles. 
            </p>
          </div>
          <Product ProductData={TableData} name="Optical Table Top" />
        </div>
      </section>
      <section className="bg2 product-container-wrapper " id="floor-stands">
        <div className="container py-5">
          <div className="heading text-center pb-4">
            <span> Optical Display</span>
            <h2>Floor Stands</h2>
            <p>
            Our Floor Stands are a blend of elegance and versatility, designed to maximize product visibility and accessibility. These stands are strategically crafted to not only display a variety of eyewear but also to encourage customer engagement and exploration throughout the showroom space.
            </p>
          </div>
          <Product ProductData={FloorData} name=" Optical Floor Stands" />
        </div>
      </section>
    </div>
  );
};




export default OpticalDisplay