import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import CourseBanner from "../components/CourseBanner";
import OurServices from "../components/OurServices";

const About = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const bannerData = {
    image: "/images/about-banner.jpg",
    heading: "About Us",
    heading2:"Discover Our Story",
   para: "Dive into the world of The Noor Art, where every design is a narrative of creativity and craftsmanship. Our dedicated team is committed to bringing your vision to life with unparalleled precision and artistry. Explore our journey, ethos, and the passion that drives us in every project.",
  };
  return (
    <div className="page-content">
      <Helmet>
        <title>About The Noor Art | Crafting Showrooms with Passion and Precision</title>
        <meta
          name="description"
          content="
          Discover the story behind The Noor Art, a visionary showroom design studio. Learn about our commitment to quality, our mission, and the team that makes every project a masterpiece of design and functionality."
        />
       
      </Helmet>
      <CourseBanner {...bannerData} />
      <section className="bg2">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-6">
              <div className="wrapper">
                <h2>The Noor Art</h2>
                <p>
                Welcome to The Noor Art, a sanctuary of design innovation and artistic mastery in showroom creation, with a special focus on the optical industry. Our team, a blend of visionary designers, adept craftsmen, and strategic thinkers, stands at the forefront of transforming commercial spaces into captivating experiences. Founded on a bedrock of creativity, precision, and passion, we have charted a course in showroom design that transcends conventional boundaries.</p>
                <p>
                Our journey began with a simple yet profound ambition: to redefine the landscape of retail spaces. At The Noor Art, we do more than design showrooms; we craft environments that narrate a brand's story, capturing its essence in every curve and corner. Our expertise extends beyond mere aesthetics; it delves into the psychology of space, understanding how each element influences customer behavior and interaction.
                </p>
           <p>With a rich tapestry of successful projects behind us, we've established ourselves as not just designers, but as architects of experience. Our approach is holistic, considering every facet of the showroom from lighting and layout to material selection and brand integration. This meticulous attention to detail ensures that every project we undertake is not just a space, but a journey into the heart of the brand.</p>
              
              </div>
            </div>
           
            <div className="col-md-6">
              <div className="img-wrapper">
                <img src="/images/about-us.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <OurServices />
      <section className="bg2">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-6">
              <div className="wrapper">
                <h2>Our Commitment to Quality</h2>

                <p>
                Quality is the cornerstone of everything we do at The Noor Art. From the initial sketches to the final touches, every stage of our design and build process is marked by meticulous attention to detail and an unwavering standard of excellence. We use only the finest materials, collaborate with the best suppliers, and employ state-of-the-art techniques to ensure that each project we undertake is not just completed but perfected. Our commitment to quality extends beyond the physical space; it's about creating an experience, a place where the brand and customer connect in a meaningful way.

               
                </p>
               
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <div className="img-wrapper">
                <img src="/images/commitment.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-5">
              <div className="img-wrapper">
                <img src="/images/purpose.jpg" alt="" />
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-6">
              <div className="wrapper">
                <h2>Our Mission</h2>

                <p>
                Our mission is twofold: to elevate the brand identity of our clients and to enrich the customer experience through exceptional design. We believe that a well-designed showroom is more than just a space - it's a narrative that tells the story of the brand, engages the senses, and invites interaction. At The Noor Art, we are dedicated to creating spaces that not only display products but also communicate the ethos and values of the brands they represent. Our goal is to create environments that resonate with customers, encouraging them to return time and again.
                </p>
               
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
