import React from "react";
import { NavLink } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";

const OurServices = () => {
  return (
    <section>
      <div className="container py-5 service-wrapper">
        <div className="heading text-center pb-4">
        <span>Our Main</span>
          <h2> Services</h2>
          <p>At The Noor Art, we offer a spectrum of services tailored to meet the specific needs of our clients</p>
        </div>
        <div className="row">
          <div className="col-lg-4 md-6 mt-4">
            <div className="wrapper">
              <div className="img-wrapper">
                <img src="/images/home/service1.jpeg" alt="" />
              </div>
              <div className="text-wrapper">
                <div className="text">
                  <h3>Custom Showroom Design</h3>
                  <p>
                  At The Noor Art, our Custom Showroom Design service is the cornerstone of our creative...
                  </p>
                  <NavLink className="effect" to="/service#service1">
                    Read More
                    <FaAngleRight />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4  md-6 mt-4">
            <div className="wrapper">
              <div className="img-wrapper">
                <img src="/images/home/service2.jpeg" alt="" />
              </div>
              <div className="text-wrapper">
                <div className="text">
                  <h3>Space Planning and Layout</h3>
                  <p>
                  Effective Space Planning and Layout are crucial for a successful showroom. At The Noor Art, we meticulously...
                  </p>
                  <NavLink className="effect" to="/service#service2">
                    View Detail
                    <FaAngleRight />{" "}
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4  md-6 mt-4">
            <div className="wrapper">
              <div className="img-wrapper">
                <img src="/images/home/service3.jpeg" alt="" />
              </div>
              <div className="text-wrapper">
                <div className="text">
                  <h3>Lighting and Visual Elements</h3>
                  <p>
                  Lighting and Visual Elements play a pivotal role in setting the mood and highlighting your products...
                  </p>
                  <NavLink className="effect" to="/service#service3">
                    View Detail
                    <FaAngleRight />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurServices;
