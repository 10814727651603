import React, { useEffect } from "react";

import "./home.css";
import HomeBanner from "../components/HomeBanner";
import { NavLink, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import HomeCategories from "../components/HomeCategories";
import { FaAngleRight } from "react-icons/fa";
import OurServices from "../components/OurServices";
import Testimonials from "../components/Testimonials";
const Home = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="page-content">
      <Helmet>
        <title>The Noor Art | Innovative Showroom Designs for Optical, Watch, and Jewelry</title>
        <meta
          name="description"
          content="Welcome to The Noor Art – Your destination for bespoke showroom designs. Specializing in optical, watch, and jewelry displays, we bring creativity and functionality together to transform your retail space."
        />

      </Helmet>

      <div className="home-banner-wrapper">
        <HomeBanner />
      </div>

<section className="exp-wrapper">
<div className="container ">
<div className="wrapper py-5 text-center">
<h2>18+ Years Experience</h2>
<p>18+ Years of Crafting Excellence! Your Vision, Our Legacy in Showroom Design</p>

</div>


</div>

</section>

      <section>
      <div className="container py-5">
        <div className="row">
          <div className="col-md-5">
            <div className="wrapper">
              <h2>Who We Are</h2>
              <p>
              Welcome to The Noor Art, a beacon of creativity and innovation in the world of showroom design. At the heart of our ethos lies a commitment to transforming spaces into vibrant, visually stunning environments that not only reflect the uniqueness of each brand but also enhance the customer experience. Our team, composed of skilled designers and craftsmen, specializes in creating bespoke showrooms with a focus on optical retail spaces. With years of experience and a passion for design excellence, The Noor Art is dedicated to bringing your vision to life, ensuring every detail resonates with elegance and precision.
              </p>
            
            </div>
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-6">
            <div className="img-wrapper">
              <img src="/images/home/home-about.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>



      <HomeCategories />
      <OurServices />
   <section className="bg2 testimonial">
   <div className="container py-5">
   <div className="heading text-center pb-4">
   <span>Our Latest</span>
   <h2>TESTIMONIALS</h2>
   <p>
   Hear from our satisfied clients
   </p>
 </div>

 <Testimonials />
   
   </div>
   
   </section>

      <section>
        <div className="container py-5 service-wrapper">
          <div className="heading text-center pb-4">
          <span>Our Latest</span>
            <h2> Blogs</h2>
            <p>
           Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptas, maiores?
            </p>
          </div>
          <div className="row">
            <div className="col-lg-4 col-bd-6 mt-4">
              <div className="wrapper">
                <div className="img-wrapper">
                  <img src="/images/home/blog1.jpeg" alt="" />
                </div>
                <div className="text-wrapper">
                  <div className="text">
                    <p>April 9, 2022 No Comments</p>
                    <h3>Lorem, ipsum dolor.</h3>
                    <p>
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Quod, exercitationem...
                    </p>

                    <NavLink className="effect" to="/">
                    Read More
                    <FaAngleRight />
                  </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-bd-6 mt-4">
              <div className="wrapper">
                <div className="img-wrapper">
                  <img src="/images/home/blog2.jpeg" alt="" />
                </div>
                <div className="text-wrapper">
                  <div className="text">
                    <p>April 9, 2022 No Comments</p>
                    <h3>Lorem, ipsum dolor.</h3>
                    <p>
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Quod, exercitationem...
                    </p>

                    <NavLink className="effect" to="/">
                    Read More
                    <FaAngleRight />
                  </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-bd-6 mt-4">
              <div className="wrapper">
                <div className="img-wrapper">
                  <img src="/images/home/blog3.jpeg" alt="" />
                </div>
                <div className="text-wrapper">
                  <div className="text">
                    <p>April 9, 2022 No Comments</p>
                    <h3>Lorem, ipsum dolor.</h3>
                    <p>
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Quod, exercitationem...
                    </p>

                    <NavLink className="effect" to="/">
                      Read More
                      <FaAngleRight />
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
