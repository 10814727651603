import React from "react";
import Slider from "react-slick";

const HomeBanner = () => {
  const settings = {
    infinite: true,
    dots: true,
    arrows: false,
    slidesToShow: 1,
    speed: 1000,
    // autoplay: true,
    // autoplaySpeed: 3000,
    // pauseOnHover: true,
  };
  return (
    <div>
      <Slider {...settings}>
        <div className="swiper-slide">
          <div className="banner-item ">
            <div className="img">
              <img src="/images/home/banner2.jpg" alt="" />
            </div>
          </div>
        </div>
        <div className="swiper-slide">
        <div className="banner-item ">
          <div className="img">
            <img src="/images/home/banner5.jpg" alt="" />
          </div>
        </div>
      </div>
      <div className="swiper-slide">
      <div className="banner-item ">
        <div className="img">
          <img src="/images/home/banner3.jpg" alt="" />
        </div>
      </div>
    </div>
    <div className="swiper-slide">
    <div className="banner-item ">
      <div className="img">
        <img src="/images/home/banner4.jpg" alt="" />
      </div>
    </div>
  </div>
  <div className="swiper-slide">
  <div className="banner-item ">
    <div className="img">
      <img src="/images/home/banner1.jpg" alt="" />
    </div>
  </div>
</div>

      </Slider>
    </div>
  );
};

export default HomeBanner;
