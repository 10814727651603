import React, { useEffect } from "react";
import "./common.css";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Forme from "../components/FormComponent";
import { NavLink } from "react-router-dom";
import {
  FaLinkedin,
  FaSquareInstagram,
  FaLocationDot,
  FaPhone,
  FaRegEnvelope,
  FaSquareXTwitter,
} from "react-icons/fa6";
import { TbDeviceLandlinePhone } from "react-icons/tb";
import { FaFacebookSquare } from "react-icons/fa";
import CourseBanner from "../components/CourseBanner";

const Contact = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const bannerData = {
    image: "/images/contact-banner.jpg",
    heading: "Contact Us",
    heading2: "Reach Out for Excellence",
    para: " Your vision deserves the best, and we at The Noor Art are here to bring that vision to life. Our team eagerly awaits your inquiries and ideas. With a commitment to exceptional service and attention to detail, we ensure your experience with us is as extraordinary as our designs. Contact us to start crafting your dream showroom.",
  };
  return (
    <div className="page-content">
      <Helmet>
        <title>
          Contact The Noor Art | Get in Touch for Showroom Design Services
        </title>
        <meta
          name="description"
          content="Ready to elevate your showroom design? Contact The Noor Art today. Our team is eager to assist with your queries and collaborate on creating a space that truly reflects your brand's essence"
        />
      </Helmet>
      <CourseBanner {...bannerData} />

      <div className="bg2">
        <div className="container py-5 ">
          <div className="row contact-wrapper">
            <div className="col-md-6 py-5">
              <Forme display="d-none" />
            </div>
            <div className="col-md-6 footer-info py-4">
              <div className="footer-wrapper px-4">
                <div className="wrapper">
                  <h2 className="text-white">Contact Info</h2>
                  <ul className="ps-0">
                    <li className="d-flex mt-4">
                      <span className="icon-box me-2">
                        <FaLocationDot />
                      </span>
                      <span>
                        <p className="">
                          Ha no 41 Gf street no 1 villaage sher pur new Delhi
                          110094
                        </p>
                      </span>
                    </li>
                    <p className="mb-0 mt-3">
                      <strong>Call Us</strong>
                    </p>

                    <li>
                      <span className="icon-box me-2">
                        <FaPhone />
                      </span>
                      <NavLink
                        className="effect effect-white"
                        to="tel:9999712523"
                      >
                        {" "}
                        9999712523
                      </NavLink>
                    </li>
                    <li>
                      <span className="icon-box me-2">
                        <FaPhone />
                      </span>
                      <NavLink
                        className="effect effect-white"
                        to="tel:9958946561"
                      >
                        {" "}
                        9958946561
                      </NavLink>
                    </li>
                    <li>
                      <span className="icon-box me-2">
                        <FaPhone />
                      </span>
                      <NavLink
                        className="effect effect-white"
                        to="tel:8447217307"
                      >
                        {" "}
                        8447217307
                      </NavLink>
                    </li>
                    <li>
                      <span className="icon-box me-2">
                        <TbDeviceLandlinePhone />
                      </span>
                      <NavLink
                        className="effect effect-white"
                        to="tel:1179680932"
                      >
                        {" "}
                        1179680932
                      </NavLink>
                    </li>
                    <p className="mb-0 mt-3">
                      <strong>Mail Us</strong>
                    </p>
                    <li>
                      <span className="icon-box me-2">
                        <FaRegEnvelope />
                      </span>

                      <NavLink
                        className="effect effect-white"
                        to="mailto:thenoorart@gmail.com"
                        target="_blank"
                        rel="noopener"
                      >
                        thenoorart@gmail.com
                      </NavLink>
                    </li>
                  </ul>
                  <div className="socal">
                    <p className="mb-0">
                      <strong>Follow Us</strong>
                    </p>
                    <NavLink
                      to="https://www.linkedin.com/in/the-noor-art/"
                      target="_blank"
                      rel="noopener"
                    >
                      <FaLinkedin />
                    </NavLink>
                    <NavLink
                      to="https://www.facebook.com/thenoorart1/"
                      target="_blank"
                      rel="noopener"
                    >
                      <FaFacebookSquare />
                    </NavLink>
                    <NavLink
                      to="https://www.instagram.com/thenoorart1/"
                      target="_blank"
                      rel="noopener"
                    >
                      <FaSquareInstagram />
                    </NavLink>

                    <NavLink
                      to="https://twitter.com/thenoorart"
                      target="_blank"
                      rel="noopener"
                    >
                      <FaSquareXTwitter />
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
